export interface AirCleaningDevice {
  title: string;
  imgSrc: string;
  roomSize: number;
  fanSpeeds: string;
  dimensions: {
    width: number;
    depth: number;
    height: number;
  };
  weight: number;
  energyConsumption: string;
  soundLevel: string;
  certification?: string;
  wallMountable: boolean;
  canadianAffiliateLink: string;
  americanAffiliateLink: string;
}

export const AIR_CLEANING_DEVICES: AirCleaningDevice[] = [
  {
    title: 'Delos Intellipure Compact',
    imgSrc: 'Intellipure-Compact.png',
    roomSize: 545,
    fanSpeeds: 'Low, Medium, High, Turbo',
    dimensions: {
      width: 13,
      depth: 8.75,
      height: 20,
    },
    weight: 17,
    energyConsumption: '9-65 Watts',
    soundLevel: '39.50 - 64.1 dB',
    wallMountable: false,
    canadianAffiliateLink: 'https://shop.deloscanada.ca/discount/CAN_ParticleOne_Compact?redirect=%2Fproducts%2Fdelos-compact',
    americanAffiliateLink: 'https://shop.delos.com/discount/US_ParticleOne_Compact?redirect=%2Fproducts%2Fdelos-compact',
  },
  {
    title: 'Delos Intellipure 468',
    imgSrc: 'Intellipure-468.png',
    roomSize: 1000,
    fanSpeeds: 'Fine-Tuning Enabled',
    dimensions: {
      width: 13.4,
      depth: 10.6,
      height: 30,
    },
    weight: 27,
    energyConsumption: '95 Watts (max)',
    soundLevel: '47 - 66 dB',
    certification: 'UL867, CARB, CE',
    wallMountable: false,
    canadianAffiliateLink: 'https://shop.deloscanada.ca/discount/CAN_ParticleOne_468?redirect=%2Fproducts%2Fdelos-468-ultrafine',
    americanAffiliateLink: 'https://shop.delos.com/discount/US_ParticleOne_468?redirect=%2Fproducts%2Fdelos-468-ultrafine',
  },
  {
    title: 'Delos Intellipure 950P',
    imgSrc: 'Intellipure-950P.png',
    roomSize: 3500,
    fanSpeeds: 'Fine-Tuning Enabled',
    dimensions: {
      width: 18,
      depth: 28,
      height: 48,
    },
    weight: 155,
    energyConsumption: '100 Watts - 560 Watts',
    soundLevel: '63 dB',
    wallMountable: false,
    canadianAffiliateLink: 'https://shop.deloscanada.ca/discount/CAN_ParticleOne_950P?redirect=%2Fproducts%2Fdelos-950p-commercial',
    americanAffiliateLink: 'https://shop.delos.com/discount/US_ParticleOne_950P?redirect=%2Fproducts%2Fdelos-950p-commercial',
  }
];