<template>
  <div>
    <!-- Banner -->
    <div class="flex justify-center items-center w-full banner">
      <div class="grid grid-cols-6 gap-4">
        <div class="mx-4 xl:mx-20 col-span-4">
          <h3 class="text-xl lg:text-2xl xl:text-3xl text-slate-900 font-bold pt-4">Recommended products and technology
            for <span class="text-pink-600">safer spaces</span></h3>
          <p class="text-gray-600 lg:text-base xl:text-xl pt-3">The success of a ParticleOne mitigation plan requires
            the
            integration of
            validated products
            and
            technology. The following solutions have been assessed and meet ParticleOne criteria for effective,
            science-based tools and technology.</p>
        </div>
        <div class="flex col-span-2 justify-end">
          <img src="@/assets/svg/icons-top.svg" alt="products and solution icons">
        </div>
      </div>
    </div>
    <div class="p-4 xl:p-20">
      <div class="flex mb-16">
        <div class="flex-none">
          <img src="@/assets/svg/delos-logo.svg" alt="Delos Logo" class="h-full w-32 lg:w-48 xl:w-64 my-auto" />
        </div>
        <div class="flex-grow mx-6">
          <h4 class="lg:text-xl xl:text-2xl font-bold">Recommended air-cleaning devices</h4>
          <p class="text-base xl:text-lg mr-4">Delos, a leader in developing advanced air purification systems,
            offers patented Disinfecting Filtration System (DFS) technology that efficiently reduces airborne
            microorganisms.
            Three Delos Intellipure models utilize DFS technology and are viable air-cleaning options for
            ParticleOne mitigation plans. <a target="_blank" href="https://help.particleone.com/delos-dfs-systems/"
              class="text-pink-600 underline hover:no-underline">Learn more.</a></p>
        </div>
      </div>
      <div class="xl:grid grid-cols-5 gap-16">
        <div class="col-span-3">
          <div class="grid gap-5">
            <delos-product-card v-for="airCleaningDevice in airCleaningDevices" :key="airCleaningDevice.title"
              :airCleaningDevice="airCleaningDevice"></delos-product-card>
            <p class="font-semibold text-xl text-gray-700">For orders greater than 20 units, please <a
                class="text-pink-600" :href="mailToURL">contact
                us</a> for
              special pricing.</p>

          </div>
        </div>
        <div class="mt-6 xl:mt-0 col-span-2">
          <div class="flex justify-center items-center py-8 bg-gray-100 border-2 rounded-lg">
            <div class="w-3/4">
              <h1 class="text-2xl font-bold mb-3">Confirm the best DFS system for your space</h1>
              <p class="text-base text-gray-600 font-medium mb-3">Complete the following fields to determine the right
                model
                and number of Intellipure unit(s) required
                for your space.</p>
              <iframe src="https://app.calconic.com/api/embed/calculator/62dee14b27415c002aa3aa03"
                sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups"
                title="Calconic_ Calculator" name="Calconic_ Calculator" height="735px" scrolling="no"
                style="width: 100%; border: 0; outline: none;"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
          
<script>
import DelosProductCard from '@/components/dashboard/products-and-solutions/DelosProductCard.vue';

import {
  AIR_CLEANING_DEVICES
} from '@/constants/delos-products.ts';
import { computed } from 'vue';


export default {
  name: 'DashboardSpaceProductsAndSolutions',
  components: {
    DelosProductCard,
  },
  mounted() {
    (function () {
      window.addEventListener('message', function (t) {
        var e = 'https://www.paypalobjects.com/api/checkout.4.0.141.js';
        if (!document.querySelector(`script[src="${e}"]`) && t.data && 'LOAD_PAYPAL' === t.data.action) {
          var a = document.createElement('script'); a.src = e, a.async = !0, a.dataset = { paypalCheckout: !0, noBridge: !0, state: 'ppxo_meta', env: 'production' }, document.body.append(a);
        } if (t.data && 'CALCONIC_UPDATE_HEIGHT' === t.data.action) {
          var s = document.querySelector(`iframe[src="https://app.calconic.com/api/embed/calculator/${t.data.payload.id}"]`);
          if (s) { s.height = t.data.payload.height; }
        }
      });
    });
  },
  setup() {
    const mailToHelpURL = 'mailto:help@particleone.com';
    const mailToSubject = encodeURIComponent('Delos Intellipure units special pricing request');
    const mailToBody = encodeURIComponent('I am interested in a volume purchase of 20 or more Delos Intellipure air-cleaning devices. Please contact me at this email with pricing information.');
    const mailToURL = computed(() => `${mailToHelpURL}?subject=${mailToSubject}&body=${mailToBody}`);
    const airCleaningDevices = AIR_CLEANING_DEVICES;
    return {
      mailToURL,
      airCleaningDevices
    };
  }
};
</script>

<style scoped>
.banner {
  height: 310px;
  background: url('../../assets/svg/background-lines.svg') top left no-repeat, rgba(241, 245, 249, 1.0);
}
</style>